
import { ref, defineComponent } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric, minLength } from "@vuelidate/validators";
import InputMask from "primevue/inputmask";
import Textarea from "primevue/textarea";
import * as EmailServices from "../api/helpers/Email";
import Swal from "sweetalert2";
import { getCurrentInstance } from "vue";
import * as FacebookServices from "../api/helpers/Facebook";

export default defineComponent({
  name: "Contact",
  components: {
    Button,
    InputText,
    InputMask,
    Textarea,
  },
  setup() {
    var loading = ref(false);
    const f = ref({
      subject: "Contacto",
      template: "contact",
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    const rules = {
      name: { required },
      email: { required, email },
      phone: { required, numeric, minLength: minLength(10) },
      message: { required },
    };

    const submitted = ref(false);
    const v$ = useVuelidate(rules, f);

    const resetForm = () => {
      f.value.name = "";
      f.value.email = "";
      f.value.phone = "";
      f.value.message = "";
      submitted.value = false;
      loading.value = false;
    };
    const app = getCurrentInstance();
    const toastTime = app?.appContext.config.globalProperties.$toastTime;

    return {
      f,
      v$,
      submitted,
      loading,
      toastTime,
      resetForm,
    };
  },
  mounted() {
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Contacto";
    window.scrollTo(0, 0);
  },
  methods: {
    sendEmail(isFormValid: boolean) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }

      this.loading = true;
      EmailServices.email(this.f).then(
        () => {
          Swal.fire(
            "Enviado",
            "En breve nos pondremos en contacto contigo.",
            "success"
          );
          this.resetForm();
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: err,
            life: this.toastTime,
          });
        }
      );
    },
  },
});
